<template>
  <div class="operations_report_detail">
    <content-block :title="getReportTitle()">
      <query-form label-width="100px" @search="handleSearch" class="content_block" @reset="handleReset">
        <template v-if="type === 'TIMECONSUME'">
          <el-form-item label="工单编号">
            <el-input placeholder="请输入工单编号" v-model="model.workOrderCode"></el-input>
          </el-form-item>
          <el-form-item label="工单类型">
            <el-select placeholder="请选择工单类型" v-model="model.workOrderType" @change="handleSearch">
              <el-option :value="null" label="全部"></el-option>
              <el-option v-for="i in orderCategoryList" :key="i.id" :label="i.name" :value="i.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工单状态">
            <el-select placeholder="请选择工单状态" v-model="model.workOrderStatus"  @change="handleSearch">
              <el-option v-for="i in OrderStatusEnumList" :key="i.value" :label="i.name" :value="i.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院名称">
            <el-input placeholder="请输入医院名称" v-model="model.customerName"></el-input>
          </el-form-item>
          <el-form-item label="工单负责人">
            <el-input placeholder="请输入工单负责人" v-model="model.principalName"></el-input>
          </el-form-item>
        </template>
        <template v-else-if="type === 'ORDERCOUNT'">
          <el-form-item label="服务人员">
            <el-input placeholder="请输入服务人员" v-model="model.employeeName"></el-input>
          </el-form-item>
          <el-form-item label="部门">
            <department-selector v-model="model.departmentId" isAllowLeaf  @change="handleSearch"></department-selector>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </template>
      </query-form>
      <table-with-pagination :tableData="reportData"
        ref="orderTable"
        :columnList="columnList"
        :filterConfig="{remote: true}"
        @filter-change="handleFilterChange"
        :pagination.sync="pagination"
        @current-change="getTableList"
      />
    </content-block>
    <el-dialog :visible.sync="dialogVisible"
      width="800px"
      title="工单作业量明细">
      <div>
        <el-descriptions>
          <el-descriptions-item label="服务人员">{{currentEngineer?currentEngineer.employeeName:''}}</el-descriptions-item>
        </el-descriptions>
        <table-with-pagination :tableData="engineerOrderList"
          :columnList="engineerOrderColumnList"
          :pagination.sync="engineerOrderPageInfo"
          @current-change="getEngineerOrderList"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getFormTypeListByConfigId } from '@/api/formSetting';
import { getOrderTimeConsumingReport, getOrderCountReport, getEngineerOrderList } from '@/api/report';
import { wrapWithAll } from '@/utils/Enum';
import { OrderStatusEnumList } from '@/enums/OrderStatusEnum';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import departmentSelector from '@/components/departMentSelector';

export default {
  name: 'operations_report_detail',
  mixins: [
    TablePaginationMixin,
  ],
  components: {
    departmentSelector,
  },
  props: {
    type: String,
    // TIMECONSUME 工单时效
    // ORDERCOUNT 作业量
  },
  data () {
    const showOrderFilters = [
      { label: '有数据', value: 1, checked: true },
      { label: '无数据', value: 2, checked: true },
    ];
    let columnList = [];
    if (this.type === 'TIMECONSUME') {
      columnList = [
        {
          tooltip: true,
          label: '工单编号',
          minWidth: '140px',
          prop: 'workOrderCode',
        },
        {
          tooltip: true,
          label: '工单类型',
          minWidth: '140px',
          prop: 'workOrderTypeMsg',
        },
        {
          tooltip: true,
          label: '工单状态',
          minWidth: '140px',
          prop: 'workOrderStatusMsg',
        },
        {
          tooltip: true,
          label: '派单时效（小时）',
          minWidth: '140px',
          prop: 'dispatchDuration',
        },
        {
          tooltip: true,
          label: '接单时效（小时）',
          minWidth: '140px',
          prop: 'receivedDuration',
        },
        {
          tooltip: true,
          label: '完成时效（小时）',
          minWidth: '140px',
          prop: 'completedDuration',
        },
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '140px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '工单负责人',
          minWidth: '140px',
          prop: 'principalName',
        },
      ];
    } else if (this.type === 'ORDERCOUNT') {
      columnList = [
        {
          tooltip: true,
          label: '服务人员',
          minWidth: '140px',
          prop: 'employeeName',
          render: (h, {row})=>{
            return <span class='text-primary cursor-pointer' onClick={this.handleDisplayEngineerDetail.bind(this, row)}>{row.employeeName}</span>;
          },
        },
        {
          tooltip: true,
          label: '部门',
          minWidth: '140px',
          prop: 'departmentName',
        },
        {
          tooltip: true,
          label: '相关数据',
          minWidth: '140px',
          prop: 'showWorkOrderMsg',
          filters: showOrderFilters,
        },
        {
          tooltip: true,
          label: '工单总量',
          minWidth: '140px',
          prop: 'totalQuantity',
        },
        {
          tooltip: true,
          label: '未完成工单量',
          minWidth: '140px',
          prop: 'unCompleteQuantity',
        },
        {
          tooltip: true,
          label: '已完成工单量',
          minWidth: '140px',
          prop: 'completeQuantity',
        },
        {
          tooltip: true,
          label: '维修满意度',
          minWidth: '140px',
          prop: 'avgEvaluateSatisfy',
        },
        {
          tooltip: true,
          label: '服务态度',
          minWidth: '140px',
          prop: 'avgServiceSpeed',
        },
        {
          tooltip: true,
          label: '响应速度',
          minWidth: '140px',
          prop: 'avgResponseSpeed',
        },
      ];
    }
    return {
      model: {},
      columnList,
      showOrderFilters,
      reportData: [],
      OrderStatusEnumList: wrapWithAll(OrderStatusEnumList, '全部'),
      orderCategoryList: [],
      dialogVisible: false,
      currentEngineer: null,
      engineerOrderList: [],
      engineerOrderPageInfo: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      engineerOrderColumnList: [
        {
          tooltip: true,
          label: '工单编号',
          minWidth: '140px',
          prop: 'workOrderCode',
        },
        {
          tooltip: true,
          label: '工单类型',
          minWidth: '140px',
          prop: 'workOrderTypeMsg',
        },
        {
          tooltip: true,
          label: '工单状态',
          minWidth: '140px',
          prop: 'workOrderStatusMsg',
        },
        {
          tooltip: true,
          label: '医院名称',
          minWidth: '140px',
          prop: 'customerName',
        },
        {
          tooltip: true,
          label: '维修满意度',
          minWidth: '140px',
          prop: 'evaluateSatisfy',
        },
        {
          tooltip: true,
          label: '服务态度',
          minWidth: '140px',
          prop: 'serviceSpeed',
        },
        {
          tooltip: true,
          label: '响应速度',
          minWidth: '140px',
          prop: 'responseSpeed',
        },
        {
          tooltip: true,
          label: '创建时间',
          minWidth: '140px',
          prop: 'workOrderCreateTime',
        },
      ],
    };
  },
  methods: {
    handleFilterChange ({values}) {
      if (values.length === 0) {
        this.model.showWorkOrder = null;
      } else {
        this.model.showWorkOrder = values.reduce((pre, current)=>pre + current, 0);
      }
      this.handleSearch();
    },
    getReportTitle () {
      if (this.type === 'TIMECONSUME') {
        return '工单时效统计';
      } else if (this.type === 'ORDERCOUNT') {
        return '工单作业量统计';
      }
      return '';
    },
    handleReset () {
      this.setDefaultModel();
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;

      this.getTableList();
    },
    getTableList () {
      let action = null;
      if (this.type === 'TIMECONSUME') {
        action = getOrderTimeConsumingReport;
      } else if (this.type === 'ORDERCOUNT') {
        action = getOrderCountReport;
      }
      action && action({...this.model, pageSize: this.pagination.pageSize, pageNum: this.pagination.currentPage }).then((res) =>{
        this.reportData = res.body?.list || [];
        this.pagination.total = res.body?.total || 0;
      });
    },
    getEngineerOrderList () {
      getEngineerOrderList({
        id: this.currentEngineer?.employeeId,
        startDate: this.currentEngineer?.startDate,
        endDate: this.currentEngineer?.endDate,
        pageNum: this.engineerOrderPageInfo.currentPage,
        pageSize: this.engineerOrderPageInfo.pageSize,
      }).then(rep=>{
        this.engineerOrderList = rep.body.list;
        this.engineerOrderPageInfo.total = rep.body.total;
      });
    },
    handleDisplayEngineerDetail (item) {
      this.dialogVisible = true;
      this.currentEngineer = item;
      this.engineerOrderPageInfo = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
      this.getEngineerOrderList();
    },
    setDefaultModel () {
      if (this.type === 'TIMECONSUME') {
        this.model = {
          customerName: null,
          principalName: null,
          workOrderCode: null,
          workOrderStatus: null,
          workOrderType: null,
        };
      } else if (this.type === 'ORDERCOUNT') {
        this.model = {
          departmentId: null,
          departmentName: null,
          employeeName: null,
          startDate: null,
          endDate: null,
          showWorkOrder: this.showOrderFilters.reduce((pre, cur)=>pre.value + cur.value, 0),
        };
      }
    },
  },
  computed: {
    dateRange: {
      get () {
        return (this.model.startDate && this.model.endDate) ? [this.model.startDate, this.model.endDate] : [];
      },
      set (val) {
        this.model.startDate = val[0];
        this.model.endDate = val[1];
      },
    },
  },
  created () {
    if (this.type === 'TIMECONSUME') {
      getFormTypeListByConfigId({ useFlag: true, customizeSystemConfigCode: 'WORK_ORDER'}).then((ret)=>{
        this.orderCategoryList = ret.body;
      });
    }
    this.setDefaultModel();
  },
};
</script>

<style lang="scss" scoped>
.operations_report_detail {

  /deep/.vxe-table--filter-body {
    max-height: 600px!important;
  }
}

</style>
